<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container">
                <!-- Profile -->
                <base-user-profile
                    :userData="userData"
                    :user-role="userType"
                    :user-login="true"
                />
                <!-- Profile -->
                <!-- Page Contents -->
                <div class="page-contents">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div class="right-col">
                            <div class="row" id="profile-settings-section">
                                <div class="col-md-10">
                                    <base-form @submit="submit">
                                        <h2 class="page-title">
                                            {{ $t('Profile') }}
                                        </h2>
                                        <base-drop-zone
                                            :label="$t('Preview Image')"
                                            :dropzonesettings="{
                                                acceptedFiles:
                                                    '.jpg,.jpeg,.png',
                                            }"
                                            limit="1"
                                            name="preview_image"
                                            :existFiles="userImage"
                                            :error="previewPhotoError"
                                            @input="inputPhotoImage"
                                            @deleteInput="deletePhotoImage"
                                        ></base-drop-zone>
                                        <base-input
                                            :label="$t('Job title')"
                                            name="title"
                                            :placeholder="
                                                $t(
                                                    'Please enter your job title',
                                                )
                                            "
                                            :value="userProfile.title"
                                            v-model="userProfile.title"
                                            type="text"
                                            :required="true"
                                        />
                                        <!-- <base-input
                                                :label="$t('Phone')"
                                                name="phone"
                                                :placeholder="$t('Phone')"
                                                :value="userProfile.phone"
                                                v-model="userProfile.phone"
                                                type="text"
                                            /> -->
                                        <base-phone-input
                                            :label="$t('Phone')"
                                            :required="true"
                                            v-model="userProfile.phone"
                                            :value="userProfile.phone"
                                        >
                                        </base-phone-input>

                                        <!-- <base-input
                                            :label="$t('Division')"
                                            name="division"
                                            :placeholder="$t('Division')"
                                            :value="userProfile.division"
                                            v-model="userProfile.division"
                                            type="text"
                                            :required="true"
                                        /> -->
                                        <base-input
                                            :label="$t('Years of experience')"
                                            name="experience"
                                            :placeholder="
                                                $t('Years of experience')
                                            "
                                            :value="userProfile.experience"
                                            v-model="userProfile.experience"
                                            type="text"
                                            :required="true"
                                        />
                                        <div class="button-row">
                                            <b-button
                                                type="submit"
                                                variant="primary"
                                                >{{ $t('Submit') }}</b-button
                                            >
                                        </div>
                                    </base-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/ideas-submit-banner-1200x900.png'
import imgLarge from '@/assets/images/banners/ideas-submit-banner-1600x900.png'
import imgBig from '@/assets/images/banners/ideas-submit-banner-1920x600.png'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import { UsersHelper } from '@/common/crud-helpers/users'
import cloneDeep from 'lodash/cloneDeep'
import { Notify } from 'notiflix'
import { mapGetters } from 'vuex'
export default {
    components: {},
    data() {
        return {
            options: [
                { item: 'A', name: '355' },
                { item: 'B', name: '213' },
                { item: 'C', name: '1-684' },
                { item: 'D', name: '376' },
            ],
            banner: '',
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
                imgAlt: 'profile',
            },
            userImage: [],
            userType: '',
            userData: {},
            userProfile: {},
            previewPhotoError: '',
        }
    },
    async created() {
        this.setUser()
        this.setUserType()
        if (!this.dashboardSettings || !this.dashboardSettings.dashboard_tabs) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs
        ) {
            this.setBanner()
        }
    },
    methods: {
        submit() {
            let data = {}
            if (this.userProfile.title) data.title = this.userProfile.title

            if (this.userProfile.phone) data.phone = this.userProfile.phone

            // if (this.userProfile.division)
            //     data.division = this.userProfile.division

            if (this.userProfile.experience)
                data.experience = this.userProfile.experience

            if (this.userProfile.photo) data.photo = this.userProfile.photo
            else data.photo = ''

            UsersHelper.update(data)
                .then(() => {
                    window.location.href = ''
                })
                .catch(err => {
                    if (err.response.data.phone) {
                        Notify.Failure(err.response.data.phone[0])
                    }
                })
        },
        inputPhotoImage(data) {
            this.previewPhotoError = ''
            this.userProfile.photo = data
        },
        deletePhotoImage() {
            this.userProfile.photo = ''
        },
        setUserType() {
            if (this.userAuth.id) {
                this.userData = cloneDeep(this.userAuth)
                if (this.userAuth.is_mentor) {
                    this.userType = 'mentor'
                } else {
                    this.userType = 'user'
                }
            }
        },
        setUser() {
            if (this.userAuth.is_mentor) {
                this.$router.push({ name: 'profile' })
            }
            this.setUserType()

            this.userProfile = cloneDeep(this.userAuth)

            if (this.userProfile.photo) {
                if (this.userImage.length === 0) {
                    this.userImage.push({
                        filename: this.getFileName(this.userProfile.photo),
                        path: cloneDeep(this.userProfile.photo),
                    })
                }
            }
        },
        setBanner() {
            let activeTab = this.dashboardSettings.dashboard_tabs.filter(tab =>
                tab.value.url.includes(this.$route.name),
            )[0]
            this.banner = activeTab.value.banner
        },
    },
    computed: {
        ...mapGetters(['userAuth', 'dashboardSettings']),
    },
    watch: {
        userAuth() {
            this.setUser()
        },
        dashboardSettings() {
            this.setBanner()
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/ .form-control {
    &::placeholder {
        color: var(--dark);
    }
}
/deep/.drop-zone {
    .dropzone {
        .dropzone-custom-title {
            color: var(--green);
        }
        .subtitle {
            color: var(--green);
            border-color: var(--green);
        }
    }
}
.page-title {
    font-size: rem(24px);
    font-weight: 700;
    color: var(--primary);
    padding-bottom: rem(100px);
    text-transform: uppercase;
    padding-top: rem(35px);
}
.sidenav-wrapper {
    /deep/ .right-col {
        @media screen and (min-width: 1600px) {
            padding-top: 20px;
        }
        @media screen and (max-width: 1599px) {
            padding-top: 30px;
        }
        @media screen and (max-width: 1500px) {
            padding-top: 50px;
        }
        @media screen and (max-width: 991px) {
            padding-top: 20px;
        }
        @media screen and (max-width: 767px) {
            padding-top: 70px;
        }
        @media screen and (max-width: 500px) {
            padding-top: 100px;
        }
    }
}
.content-wrapper {
    padding-top: rem(170px);
    /deep/ .userProfile {
        margin-bottom: rem(80px);
    }
}
.phoneWrapper {
    /deep/ {
        .custom-select {
            max-width: 100px;
            flex: 0 0 100%;
            font-size: rem(18px);
            color: #000;
            height: rem(62px);
            border: 0;
            -webkit-box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.2);
        }
    }
    .input {
        flex: 1;
    }
}
@media screen and (max-width: 767px) {
    /deep/ {
        .page-banner {
            --h: 660px;
        }
    }
}
@media screen and (max-width: 500px) {
    /deep/ {
        .page-banner {
            --h: 770px;
        }
    }
}

#profile-settings-section {
    /deep/.v-select {
        .v-select-toggle {
            width: 108px;
            height: 3.875rem !important;
            padding: 0.625rem 3.75rem 0.625rem 1.125rem !important;
            // height: 56px;
            display: flex;
            align-items: center;
            color: #212529 !important;
            font-size: 1.125rem !important;
            box-shadow: 0 0 12px #d6d6d6;
            font-weight: 300 !important;
            &:focus,
            &:active {
                color: var(--white) !important;
            }
            .arrow-down {
                top: 35% !important;
                transform: translateY(-50%) scale(0.7);
                right: 8px;
                @media screen and (max-width: 767px) {
                    right: -22px !important;
                }
            }
        }
        .v-dropdown-container .v-dropdown-item {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            border-bottom: solid 1px #d2d2d2;
            font-weight: 600;
            font-weight: 300 !important;
            color: var(--primary) !important;
            font-size: 1.125rem !important;
        }
        .v-dropdown-container .v-dropdown-item.selected {
            background: #e4d700 !important;
            color: var(--white) !important;
        }
    }
    @media screen and (max-width: 575px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: rem(20px);
        label {
            margin: 0 0 rem(16px) 0;
        }
        .v-dropdown-container .v-dropdown-item.selected {
            background: #e4d700 !important;
            color: var(--white) !important;
        }
    }
    @media screen and (max-width: 575px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: rem(20px);
        label {
            margin: 0 0 rem(16px) 0;
        }
    }
}

.ar {
    #profile-settings-section {
        /deep/.v-select {
            .v-select-toggle {
                padding: 0.625rem 1.125rem 0.625rem 3.75rem !important;
                direction: ltr;
                .arrow-down {
                    left: 8px;
                    right: auto;
                    @media screen and (max-width: 767px) {
                        left: -22px !important;
                        right: auto !important;
                    }
                }
            }
        }
    }
}
</style>
